/* eslint-disable */
import { base, baseUpload, baseXlsx } from '@/utils/api'

const HEALTH_PLAN_PROPS_ENDPOINT = '/item-health-plan-props'

export default {
    getItemHealthPlanProp(id) {
        const config = {
            params: { clinicHealthPlanId }
        };
        return base.get(`${HEALTH_PLAN_PROPS_ENDPOINT}/${id}`, config);
    },
    getHealthPlanPropItems(page, tableId, query, types, status) {
        const config = {
            params: { page, tableId, query, types, status }
        };
        return base.get(`${HEALTH_PLAN_PROPS_ENDPOINT}`, config);
    },
    getItemHealthPlanPropIds(tableId) {
        const config = {
            params: { tableId }
        };
        return base.get(`${HEALTH_PLAN_PROPS_ENDPOINT}/batch/ids`, config);
    },
    createItemHealthPlanProp(props) {
        return base.post(`${HEALTH_PLAN_PROPS_ENDPOINT}`, props);
    },
    updateItemHealthPlanProp(id, props) {
        return base.put(`${HEALTH_PLAN_PROPS_ENDPOINT}/${id}`, props);
    },
    deleteItemHealthPlanProp(id) {
        return base.delete(`${HEALTH_PLAN_PROPS_ENDPOINT}/${id}`);
    },
    deleteItemHealthPlanProps(ids) {
        return base.put(`${HEALTH_PLAN_PROPS_ENDPOINT}/batch/delete`, { ids });
    },
}