<template>
    <div>
        <div class="table-details-content">
            <PageHeader>
                <template v-slot:header>
                    <b-skeleton v-if="!table" animation="fade" width="300px"></b-skeleton>
                    <div v-else>
                        <div>{{ table.name }}</div>
                    </div>
                </template>
                <template v-slot:actions>
                    <div class="actions">
                        <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && getTableItems()"/>
                        <div 
                            v-if="selecteds.length" 
                            class="batch-delete-wrapper" 
                            v-b-tooltip.hover title="Excluir items"
                            @click="deleteSelecteds"
                        >
                            <b-badge class="badge" variant="danger">{{ selecteds.length }}</b-badge>
                            <Delete class="icon" />
                        </div>
                        <b-button 
                            v-if="editable"
                            variant="outline-primary" 
                            @click="openItemModal(null)"
                        >
                            Novo item
                        </b-button>
                    </div>
                </template>
            </PageHeader>
            <ImportItemProgress :type="ITEM.ITEM_TYPE_PROCEDURE" @reload="getTableItems" />
            <b-row>
                <b-col cols="9">
                    <b-form-group>
                        <label>Item</label>
                        <b-form-input v-model="query" @input="searchDebounce" placeholder="Buscar por código ou descrição do procedimento"/>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <TypeMultiSelector 
                        :groupType="ITEM.ITEM_TYPE_PROCEDURE" 
                        v-model="types" 
                        @input="searchDebounce" 
                    />
                </b-col>
            </b-row>
            <TableProcedureItems 
                :editable="editable"
                :procedures="procedures"
                :selecteds="selecteds"
                :tableType="tableType"
                @edit="openItemModal"
                @delete="deleteItem"
                @handlerMasterSelect="handlerMasterSelect"
                @handlerSelect="handlerSelect"
            />
            <div class="pagination-position">
                <b-pagination
                    v-model="page"
                    :total-rows="count"
                    :per-page="limit"
                    first-number
                    last-number
                    size="sm"
                />
            </div>
        </div>

        <ItemHealthPlanPropsModal 
            :editable="editable"
            :groupType="ITEM.ITEM_TYPE_PROCEDURE"
            :tableId="tableId"
            :item="item"
            @hidden="() => item = null"
            @saved="onSaved"
        />
    </div>
</template>
<script>
import { debounce } from 'lodash';
import api from '@itemHealthPlanProps/api'
import * as ITEM from '@items/utils/constants'
import * as TABLE from '@tables/utils/constants'

export default {
    props: {
        multiPages: Boolean,
        editable: Boolean,
        table: Object,
    },
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),   
        Delete: () => import('@/assets/icons/delete.svg'), 
        PageHeader: () => import('@/components/General/PageHeader'),
        TypeMultiSelector: () => import('@items/components/TypeMultiSelector'),
        TableProcedureItems: () => import('@itemHealthPlanProps/layouts/TableProcedureItems'),
        ItemHealthPlanPropsModal: () => import('@itemHealthPlanProps/modals/ItemHealthPlanPropsModal'),
        ImportItemProgress: () => import('@items/components/ImportItemProgress'),
    },
    mounted() {
        this.searchDebounce = debounce(this.getTableItems, 300);
        this.getTableItems()
    },
    data() {
        return {
            ITEM,
            loading: false,
            tableId: this.$route.params.id,
            item: null,
            procedures: [],
            selecteds: [],
            types: [],
            query: null,
            status: [],
            page: 0,
            count: 0,
            limit: 0,
            searchDebounce: Function
        }
    },
    computed: {
        tableType () {
            return this.table?.name.includes('Particular') ? TABLE.TABLE_PROCEDURE_TYPE_PRIVATE : TABLE.TABLE_PROCEDURE_TYPE_HEALTH_PLAN
        }
    },
    methods: {
        openItemModal(item) {
            this.item = item
            this.$bvModal.show('item-health-plan-props-modal')
        },
        getTableItems() {
            this.loading = true
            api.getHealthPlanPropItems(this.page, this.tableId, this.query, this.types, this.status)
                .then(({ data }) => {
                    this.count = data.total
                    this.limit = data.per_page
                    this.procedures = data.data
                })
                .catch(error => this.$toast.error(error.message))
                .finally(() => {
                    this.loading = false
                })
        },
        onSaved(item) {
            const index = this.procedures.findIndex(({ id }) => id === item.id)
            index >= 0 ?
                this.procedures.splice(index, 1, item) :
                this.procedures.push(item)
        },
        async deleteItem(item) {
            try {
                await api.deleteItemHealthPlanProp(item.id)
                this.$toast.success('Item excluído com sucesso')
                const index = this.procedures.findIndex(({ id }) => id === item.id)
                this.procedures.splice(index, 1)
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                
            }
        },
        deleteSelecteds() {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir os <span>${this.selecteds.length} itens</span> selecionados?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.deleteItemHealthPlanProps(this.selecteds)
                        this.$toast.success('Itens excluídos com sucesso')
                        this.selecteds.forEach(id => {
                            const index = this.procedures.findIndex(item => item.id === id)
                            this.procedures.splice(index, 1)
                            this.page !== 1 && (this.page = 1)
                        })
                        this.selecteds = []
                    } catch (error) {
                        this.$toast.error(error.message)
                    }
                }
            })
        },
        async handlerMasterSelect(checked) {
            if (!checked) {
                this.selecteds = []
            } else if (!this.multiPages) {
                this.selecteds = this.procedures.map(({ id }) => id)
            } else {
                this.loading = true
                try {
                    const { data } = await api.getItemHealthPlanPropIds(this.tableId)
                    this.selecteds = data
                } catch (error) {
                    this.$toast.error(error.message)
                } finally {
                    this.loading = false
                }
            }
        },
        handlerSelect(item) {
            const index = this.selecteds.findIndex(id => id === item.id)
            index >= 0 ?
                this.selecteds.splice(index, 1) :
                this.selecteds.push(item.id)
        }
    },
    watch: {
        page() {
            this.getTableItems()
        }
    }
}
</script>
<style lang="scss" scoped>
.table-details-content {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    padding: 1rem;
}
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}
.batch-delete-wrapper {
    position: relative;
    cursor: pointer;

    .badge {
        position: absolute;
        right: -6px;
        top: -4px;
    }
}
</style>